<template>
  <b-list-group flush v-if="items && items.length > 0">
    <draggable
      v-model="items"
      @start="drag = true"
      @end="drag = false"
      @change="handleSort(items)"
    >
      <b-list-group-item v-for="(item, index) in items" :key="index">
        <b-link
          @click.native="handlerChangeCategory(item)"
          :class="{ active: item.name === category_name }"
        >
          + {{ item.name }}
        </b-link>
        <b-list-group>
          <draggable
            v-model="item.children"
            @start="drag = true"
            @end="drag = false"
            @change="handleSort(item.children)"
          >
            <b-list-group-item
              v-for="(i, idx) in item.children"
              :key="idx"
              class="border-0 pb-0"
            >
              <b-link
                @click.native="handlerChangeCategory(i)"
                :class="{ active: i.name === category_name }"
              >
                + {{ i.name }}
              </b-link>
              <b-list-group>
                <draggable
                  v-model="i.children"
                  @start="drag = true"
                  @end="drag = false"
                  @change="handleSort(i.children)"
                >
                  <b-list-group-item
                    v-for="(y, idy) in i.children"
                    :key="idy"
                    class="border-0 pb-0"
                  >
                    <b-link
                      @click.native="handlerChangeCategory(y)"
                      :class="{ active: y.name === category_name }"
                    >
                      {{ y.name }}
                    </b-link>
                  </b-list-group-item>
                </draggable>
              </b-list-group>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </b-list-group-item>
    </draggable>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem, BLink } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import draggable from 'vuedraggable'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BLink,
    draggable,
  },
  mixins: [general],
  data() {
    return {
      items: [],
      category_id:
        this.$route.params && this.$route.params.id
          ? this.$route.params.id
          : '',
      category_name:
        this.$route.params && this.$route.params.id
          ? this.$route.params.id.split('-')[1]
          : '',
    }
  },
  mounted() {
    this.loadList()
    // eslint-disable-next-line operator-linebreak
    this.category_id =
      this.$route.params.id && this.$route.params.id
        ? this.$route.params.id
        : ''
    // eslint-disable-next-line prefer-destructuring
    // eslint-disable-next-line operator-linebreak
    this.category_name =
      this.$route.params.id && this.$route.params.id
        ? this.$route.params.id.split('-')[1]
        : ''
  },
  methods: {
    handlerChangeCategory(item) {
      // this.$router.push(`/product_category/edit/${item.id}-${item.name}`)
      window.location.href = `/product_category/edit/${item.id}-${item.name}`
      // eslint-disable-next-line operator-linebreak
      this.category_id =
        this.$route.params.id && this.$route.params.id
          ? this.$route.params.id
          : ''
      // eslint-disable-next-line prefer-destructuring
      // eslint-disable-next-line operator-linebreak
      this.category_name =
        this.$route.params.id && this.$route.params.id
          ? this.$route.params.id.split('-')[1]
          : ''
    },
    handleSort(data) {
      const newPosition = []
      data.map((x, index) => {
        // eslint-disable-next-line no-param-reassign
        const item = {
          id: x.id,
          position: index + 1,
        }
        return newPosition.push(item)
      })
      this.changePosition(newPosition)
      this.$emit('handleSort')
    },
    async changePosition(list) {
      if (list && list.length > 0) {
        const params = {
          type: 2,
          template_id: JSON.parse(localStorage.getItem('templateID')).id,
          ids: list,
        }
        try {
          const res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/platform_category/change_position`,
            params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Congratulation !',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Sort Category Product success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login fail',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(this.showError(res.data.error, ',')),
                },
              })
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },

    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/tree?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.items = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item a {
  color: #6e6b7b;
}
.list-group-item:hover {
  cursor: pointer;
}
.list-group-item a.active {
  color: var(--primary);
}
.sortable-ghost .list-group-item,
.sortable-ghost {
  background: var(--primary);
  color: #ffffff;
}
.list-group-item .list-group-item {
  background: transparent;
}
</style>
