<template>
  <b-row>
    <b-col md="3">
      <ProductCategorySort @handleSort="handleSort" />
    </b-col>
    <b-col md="9">
      <div v-if="items && items.length > 0">
        <!-- Sort -->
        <div class="d-flex align-items mb-2">
          <div style="flex: 3;">
            <b-link to="add">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
              >
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="align-middle">Add new</span>
              </b-button>
            </b-link>
          </div>
        </div>
        <!-- Table -->
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Avatar</b-th>
              <b-th>Category name</b-th>
              <b-th>Number of products</b-th>
              <b-th>Parent's category name</b-th>
              <b-th>Display On/Off</b-th>
              <b-th>Position</b-th>
              <b-th>Created at</b-th>
              <b-th>More</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in items" :key="index">
              <b-td>
                <b-avatar :src="item.avatar" />
              </b-td>
              <b-td>
                <b-link :to="`detail/${item.id}`">
                  {{ item.name }}
                </b-link>
              </b-td>
              <b-td>
                N/A
              </b-td>
              <b-td>
                {{ item.parent_name }}
              </b-td>
              <b-td>
                <ActiveStatus :value="item.is_active" />
              </b-td>
              <b-td>
                {{ item.position }}
              </b-td>
              <b-td>
                {{ item.created_at }}
              </b-td>
              <b-td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ path: `detail/${item.id}` }">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Detail</span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="{ path: `edit/${item.id}` }">
                    <feather-icon icon="Edit3Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="handleDelete(item.id)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" sm="8" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <no-data />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BButton,
  BLink,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormSelect,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import NoData from '@/views/components/nodata/index.vue'
import ActiveStatus from '@/views/components/active-status/index.vue'
import ProductCategorySort from './ProductCategorySort.vue'

export default {
  components: {
    BButton,
    BLink,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    NoData,
    ActiveStatus,
    ProductCategorySort,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'name', label: 'Category name', sortable: true },
        { key: 'name', label: 'Number of products', sortable: true },
        { key: 'name', label: "Parent's category name", sortable: true },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'created_at', label: 'Created at', sortable: true },
        { key: 'is_active', label: 'Is active', sortable: false },
        { key: 'more', label: 'More', sortable: false },
        { key: 'sort', label: 'Sort', sortable: false },
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.loadList()
  },
  methods: {
    handleSort() {
      this.loadList()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_categories?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.perPage = res.count_page
          this.currentPage = res.current_page
          this.totalRows = res.items.length
          this.items = res.items.map(x => {
            if (x.parent_id !== 0) {
              const temp = res.items.find(y => x.parent_id === y.id_root)

              if (temp && temp.name !== undefined) {
                // eslint-disable-next-line no-param-reassign
                x.parent_name = temp.name
              } else {
                // eslint-disable-next-line no-param-reassign
                x.parent_name = 'None'
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              x.parent_name = 'None'
            }
            return x
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const templateId = JSON.parse(localStorage.getItem('templateID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_category/${id}?template_id=${templateId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Category has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
